module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"first child","short_name":"first child","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/fc-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e8ef61d762fb33eb1e7a2d321f98319a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-54265941-1","G-ECW8Z3RY1L"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"anonymize":true,"respectDNT":true,"delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
