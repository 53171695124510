import React from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"

import TaglineSVG from "../images/svg/tagline.svg"
import styles from "./tagline.module.css"

const Tagline = () => {
  const { scrollYProgress } = useViewportScroll()
  const rotation = useTransform(scrollYProgress, [0, 1], ["0deg", "180deg"])

  return (
    <motion.h2
      className={styles.tagline}
      style={{
        rotate: rotation,
      }}
    >
      <TaglineSVG />
    </motion.h2>
  )
}

export default Tagline
