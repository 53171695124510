import React from "react"
import { motion, AnimatePresence } from "framer-motion"

const PageTransition = ({ children, location }) => {
  const duration = 0.3

  const variants = {
    initial: {
      opacity: 0,
      // translateY: "-20px",
    },
    enter: {
      opacity: 1,
      // translateY: 0,
      transition: {
        duration: duration * 2,
        delay: duration,
        ease: "easeOut",
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      position: "absolute",
      transition: { duration: duration },
    },
  }

  return (
    <AnimatePresence>
      <motion.main
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        id="main"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  )
}

export default PageTransition
