import React from "react"
import Layout from "./src/components/layout"

const credit = `                                                                                                                                                                                                                                                              
                                                                                                                                                      
               ..','.   ';,.                                                    
             'd0XNWNd..dNWW0;                                                   
            ;KMMMWXKo.'OMMMNc                                 .ll;'             
           .xWMMNo..   .coo;                                  ;KMWK;            
         .;lKMMMNx:::::::::;.  .,:::'..;:c:.   .;loooc;.   .::dNMMNx:;.         
         cNMMMMMMMMMMMMMMMMK;  '0MMMKOXWMMX: .dXWMWWMMWKo. lWMMMMMMMMNl         
         ,xONMMMWKkkkkONMMMX;  '0MMMMMWNXX0;.xWMM0c;cONX0:.;O0NMMMWXOk;         
           .kMMMX:    .kMMMX;  '0MMMW0c'... .kMMMKdc:cc'.    .xWMMNc            
           .kMMMX;    .xMMMX;  ,0MMMK;       ,ONMMMMWNXkc.   .dWMMX:            
           .kMMMX;    .kMMMX;  ,0MMMO.        .;ldxOXWMMWd.  .dWMMX:            
           .kMMMX;    .xMMMX;  ,0MMMO.      .cdkx;..;0MMMO.   oWMMWk:,.         
           .kMMMX;    .xMMMX;  ,0MMMO.      .dNMMN0k0WMMXc    ;0WMMMWXc         
           .lOOOx'    .lOOOx'  .dOOOo.        ,dOKXXXKOo,      'lk0KKO;         
                                                 .....            ...           
                       ':::;.              .:ol;.  .,:::,              .;:::.   
                      .xMMMX;             .xWMMWo  ,KMMMO.             cNMMWo   
                      .xMMMX;             .oXWWKc  ,KMMMO.             cNMMWo   
                      .xMMMX;               ';;.   ,KMMMO.             cNMMWo   
      .,lxOOOOxo;.    .xMMMNxlxOOkxl'      ,dddo'  ,KMMMO.    .:oxOOkdlxNMMWo   
    .c0WMMMMMMMMW0c.  .kMMMMMMMMMMMMXl.    oWMMNc  ,KMMMO.  .oKWMMMMMMMWMMMWo   
   .dNMMWKdlld0WMWXc  .xMMMMXxllkNMMMX:    oWMMNc  ,KMMMO. .kWMMW0dlld0WMMMWo   
   :XMMWk.    .cl:,.  .xMMMNc   .dWMMWo    oWMMNc  ,KMMMO. lNMMWx.    .xWMMWo   
   oWMMNl             .xMMMX;    lNMMWo    oWMMNc  ,KMMMO..dMMMNc      cNMMWo   
   :XMMMO'    .coc;.  .xMMMX;    lNMMWo    oWMMNc  ,KMMMO. lNMMWk'    .kWMMWo   
   .oNMMWXxood0WMMXc  .xMMMX;    lNMMWo    oWMMNc  ,KMMMO. .xWMMWKxooxKWMMMWo   
     ;kNMMMMMMMMW0:.  .xMMMX;    lNMMWo    oWMMNc  ,KMMMO.  .c0WMMMMMMWNWMMWo   
       'coxkkkdl,.     ;oooc.    'lool'    ,lool.  .cooo:.    .;ldxxxo;;cooo,   

`
console.log(credit)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

const transitionDelay = 300

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
