import PropTypes from "prop-types"
import React from "react"
import Scroll from "react-scroll"

import Logo from "../images/svg/FC_Logo.svg"
import styles from "./header.module.css"

let ScrollLink = Scroll.Link

class Header extends React.Component {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.inner}>
          <h1 className={styles.logo}>
            <Logo className={styles.logoSvg} />
          </h1>
          <ul className={styles.menu}>
            <li className="mono">
              <ScrollLink
                to="about"
                spy={true}
                hashSpy={true}
                smooth={"easeInOutQuart"}
                offset={0}
                duration={900}
              >
                About
              </ScrollLink>
            </li>
            <li className="mono">
              <ScrollLink
                to="contact"
                spy={true}
                hashSpy={true}
                smooth={"easeInOutQuart"}
                offset={0}
                duration={900}
              >
                Contact
              </ScrollLink>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `First Child`,
}

export default Header
